.list-item:hover {
	background-color: #f0f0f0;
}

.list-item .ant-list-item-meta-title {
	margin-top: 0px;
	margin-bottom: 8px !important;
}

.create-envir-button {
	display: flex;
	align-items: baseline;
	justify-content: flex-end;
	margin-bottom: 16px;
}
