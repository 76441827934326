.onboarding-container {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	height: 100vh;
	gap: 1em;
	background-color: #d6e4ff;

	.onboarding-title {
		border-radius: 10px;
		background-color: #f0f5ff;
		box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
		padding: 20px;
		text-align: center;
		width: 250px;
		
		h1 {
			color: #595959;
			font-size: 24px;
			font-weight: bold;
			margin: 0;
		}
	}

	.onboarding-box {
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		padding: 20px;
		background-color: #ffffff;
		border-radius: 10px;
		box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
		width: 400px;
	}

	.onboarding-avatar {
		display: flex;
		justify-content: center;
		align-items: center;
		font-size: 40px;
		font-weight: bold;
		color: #ffffff;
		background-position: center;
		border-radius: 50%;
		height: 80px;
		width: 80px;
		margin-bottom: 20px;
	}

	.onboarding-form {
		width: 250px;
		.onboarding-form-button {
			display: flex;
			justify-content: center;
		}
	}
}









