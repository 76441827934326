
.unsuccessful-session {
    color:  rgba(168, 166, 166, 0.902) !important;
    filter: opacity(0.5) !important
}

.most-secure-rating {
    color: green !important
}

.more-secure-rating {
   color: #69b04f !important
}

.less-secure-rating {
    color: #FAB733 !important
}

.least-secure-rating {
    color: #FF0D0D !important
}