.custom-pagination-current {
	display: inline-block !important;
	padding: 0 6px !important;
	min-width: 32px !important;
	height: 32px !important;
	line-height: 30px !important;
	text-align: center !important;
	background: #fff !important;
	cursor: pointer;
	color: #1677ff !important;
	border: 1px solid #1677ff !important;
	border-radius: 6px !important;
	margin: 0 10px !important;
	font-weight: 600 !important;
}

.custom-pagination-dots {
	display: inline-block !important;
	min-width: 32px !important;
	font-weight: 600 !important;
}
