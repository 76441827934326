.customer-session-title {
	display: flex;
	justify-content: space-between;
	align-items: center;
	width: 100%;
}

.customer-setting-section {
	width: 60em;
	
	.customer-setting-title {
		margin-top: auto;
	}
	
}
