.environmentSwitch {
	background: rgb(104, 103, 103) !important;
	height: 60px !important;
	line-height: 20px !important;
}

.settings {
	position: absolute !important;
	bottom: 30px !important;
}

.feedback {
	position: absolute !important;
	bottom: 75px !important;
}

.documentation {
	position: absolute !important;
	bottom: 120px !important;
}

.ant-menu-item-selected:where(.documentation, .feedback) {
	background-color: transparent !important;
	color: #fff !important;
}

.sideMenu {
	height: 100%;
	border-right: 0;
}

.windowLayout {
	height: 100vh;
}

.siderLayout {
	width: 200;
}

.mainLayout {
	overflow: auto;
	padding: 0 24px 24px;
	height: 100%;
}

.routeNav {
	margin: 16px 0;
}

.mainContent {
	padding: 24;
	margin: 0;
	min-height: 280;
	flex-grow: 1;
}

.avatarButton {
	float: right;
}

.customerName {
	color: white;
	font-size: large;
	font-weight: bold;
}

.header {
	padding-inline: 30px !important;
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
}
