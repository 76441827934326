.App {
}

.loading {
	width: 100%;
	height: 100vh;
}

.settings-loading {
	width: 100%;
	margin-top: 80px;
}

.flex-all-center {
	display: flex;
	justify-content: center;
	align-items: center;
}

.section {
	border: 1px solid rgba(5, 5, 5, 0.06);
	padding: 16px;
	width: 100%;
	overflow-x: auto;
	.section-header {
		margin-top: 0px;
		margin-bottom: 16px;
	}
}

/* create modal style */
.create-modal {
	width: 100%;
	max-height: 100%;

	.ant-row {
		display: contents;
	}
	.ant-col-16 {
		max-width: 100%;
	}
	.ant-modal-content {
		padding: 50px 45px;
	}

	.ant-modal-title {
		margin-bottom: 35px;
		font-size: 20px;
	}

	.ant-modal-footer {
		margin-top: 45px;
		margin-bottom: 10px;
	}

	.ant-form label {
		font-size: 14px;
		font-weight: 500;
	}

	.ant-btn {
		height: 40px;
		font-weight: 500;
		padding: 4px 20px;
	}
}

.custom-table-space {
	width: 100%;
	gap: 16px !important;
}

.custom-detail-space {
	gap: 16px !important;
}
