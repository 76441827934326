.access-paragraph {
	margin-bottom: 0px !important;
}

.access-create-key {
    display: block;
    margin-bottom: 16px;
}

.access-secret {
    display: inline-block;
    padding: 2px 6px;
    color: rgb(101, 103, 110);
    background-color: rgb(239, 240, 242);
    border-radius: 4px;
}