.user-information-tab {
	padding: 1em;
	width: 100%;
}

.inactive-user {
	color: lightgrey !important;
}

.user-information-header {
    display: flex;
    justify-content: space-between;
    align-items: baseline;
    width: 100%;
}

.user-section-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;

    h5 {
        margin-top: 0px;
        margin-bottom: 16px;
    }
}