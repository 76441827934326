.account-header {
	display: flex;
	align-items: baseline;
	justify-content: space-between;

	.account-header-input {
		margin-bottom: 16px;
		width: 30%;
	}
}

.account-create-success {
	.password-container {
		margin-top: 16px;
		padding: 8px;
		border: 1px solid #d9d9d9;
		border-radius: 2px;
		background-color: #f0f0f0;
		display: flex;
		justify-content: center;
		align-items: center;

		.password-text {
			font-size: 1.2em;
			margin: 15px;
		}
	}
}
