.email-verify-container {
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	align-items: center;
	height: 100vh;
	background-color: #d6e4ff;

	.email-verify-box {
		max-width: 600px;
		padding: 24px;
		text-align: left;
		margin-top: 20vh;

		.email-verify-title {
			color: #575151 !important;
		}
		.email-verify-alert {
			margin-top: 24px;
			background-color: #f9f9f9;
			font-size: medium;
		}

		.email-verify-remind {
			display: flex;
			flex-direction: row;
			align-items: baseline;
			justify-content: flex-start;
			gap: 10px;
		}
		.email-verified-button {
			display: flex;
			align-items: center;
			justify-content: center;
			margin-top: 24px;
			padding: 12px 12px;
		}		
	}
}