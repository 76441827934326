.avatar-button-popover {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 16px;
  }
  
  .avatar-button-profile {
    margin-top: 16px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .avatar-button-username {
    font-weight: bold;
    margin-bottom: 8px;
  }
  
  .avatar-button-email {
    color: #666;
    font-size: 14px;
    text-align: center;
    margin-bottom: 16px;
  }
  
  .ant-popover-arrow {
    display: none;
  }
  
  .ant-avatar {
    cursor: pointer;
  }
  
  .ant-avatar:hover {
    opacity: 0.8;
  }
  